.ag-blue {
  line-height: 1.4;
  /*font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;*/
   font-family: "Calibri", "Segoe UI", Thonburi, Arial, Verdana, sans-serif;
  font-size: 10pt;
  color: var(--grey10);;
/* this is for the rowGroupPanel, that appears along the top of the grid */
/* this is for the column drops that appear in the toolPanel */
}
.ag-blue img {
  vertical-align: middle;
  border: 0;
}

.ag-blue .ag-icon-asc::before {
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  content: '\f062';
}

.ag-blue .ag-icon-desc::before {
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  content: '\f063';
}

.ag-blue .ag-icon-first:before {
  content: "\f113"; }

.ag-blue .ag-cell {
  font-size: 12px;
}

.ag-blue .ag-root {
  border: 1px solid var(--grid-border);
}
.ag-blue .ag-cell-not-inline-editing {
  padding: 2px;
}
.ag-blue .ag-cell-range-selected-1:not(.ag-cell-focus) {
  background-color: rgba(120,120,120,0.4);
}
.ag-blue .ag-cell-range-selected-2:not(.ag-cell-focus) {
  background-color: rgba(80,80,80,0.4);
}
.ag-blue .ag-cell-range-selected-3:not(.ag-cell-focus) {
  background-color: rgba(40,40,40,0.4);
}
.ag-blue .ag-cell-range-selected-4:not(.ag-cell-focus) {
  background-color: rgba(0,0,0,0.4);
}
.ag-blue .ag-column-moving .ag-cell {
  -webkit-transition: left 0.2s;
  -moz-transition: left 0.2s;
  -o-transition: left 0.2s;
  -ms-transition: left 0.2s;
  transition: left 0.2s;
}
.ag-blue .ag-column-moving .ag-header-cell {
  -webkit-transition: left 0.2s;
  -moz-transition: left 0.2s;
  -o-transition: left 0.2s;
  -ms-transition: left 0.2s;
  transition: left 0.2s;
}
.ag-blue .ag-column-moving .ag-header-group-cell {
  -webkit-transition: left 0.2s;
  -moz-transition: left 0.2s;
  -o-transition: left 0.2s;
  -ms-transition: left 0.2s;
  transition: left 0.2s;
}
.ag-blue .ag-cell-focus {
  border: 2px solid #217346;
}
.ag-blue .ag-cell {
  border-right: 1px dotted var(--grid-cell-border);
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-bottom: 1px dotted var(--grid-cell-border);
}
.ag-blue .ag-cell-first-right-pinned {
  border-left: 1px solid var(--grid-cell-border);
}
.ag-blue .ag-cell-last-left-pinned {
  border-right: 1px solid var(--grid-cell-border);
}
.ag-blue .ag-cell-highlight {
  border: 1px solid #006400;
}
.ag-blue .ag-cell-highlight-animation {
  -webkit-transition: border 1s;
  -moz-transition: border 1s;
  -o-transition: border 1s;
  -ms-transition: border 1s;
  transition: border 1s;
}
.ag-blue .ag-value-change-delta {
  padding-right: 2px;
}
.ag-blue .ag-value-change-delta-up {
  color: #006400;
}
.ag-blue .ag-value-change-delta-down {
  color: #8b0000;
}
.ag-blue .ag-value-change-value {
  background-color: transparent;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  padding-left: 1px;
  padding-right: 1px;
  -webkit-transition: background-color 1s;
  -moz-transition: background-color 1s;
  -o-transition: background-color 1s;
  -ms-transition: background-color 1s;
  transition: background-color 1s;
}
.ag-blue .ag-value-change-value-highlight {
  background-color: #cec;
  -webkit-transition: background-color 0.1s;
  -moz-transition: background-color 0.1s;
  -o-transition: background-color 0.1s;
  -ms-transition: background-color 0.1s;
  transition: background-color 0.1s;
}
.ag-blue .ag-rich-select {
  font-size: 12px;
  border: 1px solid var(--primary-lighter);
}
.ag-blue .ag-rich-select-value {
  padding: 2px;
}
.ag-blue .ag-rich-select-list {
  border-top: 1px solid var(--grey03);
}
.ag-blue .ag-rich-select-row {
  padding: 2px;
}
.ag-blue .ag-rich-select-row-selected {
  background-color: var(--grey04);
}
.ag-blue .ag-large-text {
  border: 1px solid var(--grid-cell-border);
}
.ag-blue .ag-header {
  color: var(--grey00);
  background: var(--grid-header);
  border-bottom: 1px solid var(--grid-header-border);
  font-size: 12px;
  /*font-weight: 600;*/
}
.ag-blue .ag-header-icon {
  color: var(--grey00);
  stroke: none;
}
.ag-blue .ag-no-scrolls .ag-header-container {
  background: var(--grid-header);
  border-bottom: 1px solid var(--grid-header-border);
}
.ag-blue .ag-header-cell {
  border-right: 1px solid var(--grid-header-border);
}
.ag-blue .ag-header-cell-moving .ag-header-cell-label {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.ag-blue .ag-header-cell-moving {
  background-color: #bebebe;
}
.ag-blue .ag-header-group-cell {
  border-right: 1px solid var(--grid-header-border);
}
.ag-blue .ag-header-group-cell-with-group {
  border-bottom: 1px solid var(--grid-header-border);
}
.ag-blue .ag-header-cell-label {
  padding: 4px 2px 4px 2px;
}
.ag-blue .ag-header-cell-text {
  padding-left: 2px;
}
.ag-blue .ag-header-group-cell-label {
  padding: 4px;
  padding-left: 10px;
}
.ag-blue .ag-header-group-text {
  margin-right: 2px;
}
.ag-blue .ag-header-cell-menu-button {
  padding: 2px;
  margin-top: 4px;
  border: 1px solid transparent;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-sizing: content-box /* When using bootstrap, box-sizing was set to 'border-box' */;
  -moz-box-sizing: content-box /* When using bootstrap, box-sizing was set to 'border-box' */;
  box-sizing: content-box /* When using bootstrap, box-sizing was set to 'border-box' */;
  line-height: 0px /* normal line height, a space was appearing below the menu button */;
}
.ag-blue .ag-pinned-right-header {
  border-left: 1px solid var(--grid-cell-border);
}
.ag-blue .ag-header-cell-menu-button:hover {
  border: 1px solid var(--grid-header-border);
}
.ag-blue .ag-body {
  background-color: var(--grey01);
}
.ag-blue .ag-row-odd {
  border-bottom: var(--primary);
  /* background-color: var(--grid-row-odd); */
  /* background-color: #ddebf7; */
}
.ag-blue .ag-row-even {
  background-color: var(--grid-row-even);
}
.ag-blue .ag-row-selected {
  background-color: var(--grid-row-selected);
}
.ag-blue .ag-floating-top .ag-row {
  background-color: var(--grey01);
}
.ag-blue .ag-floating-bottom .ag-row {
  background-color: var(--grey01);
}
.ag-blue .ag-overlay-loading-wrapper {
  background-color: rgba(255,255,255,0.5);
}
.ag-blue .ag-overlay-loading-center {
  border: 1px solid var(--grid-cell-border);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
  color: var(--grey10);
}
.ag-blue .ag-overlay-no-rows-center {
  border: 1px solid var(--grid-cell-border);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
}
.ag-blue .ag-group-cell-entire-row {
  background-color: var(--grey01);
  padding: 2px;
}
.ag-blue .ag-footer-cell-entire-row {
  background-color: var(--grey01);
  padding: 2px;
}
.ag-blue .ag-group-cell {
  font-style: italic;
}
.ag-blue .ag-group-expanded {
  padding-right: 4px;
}
.ag-blue .ag-group-contracted {
  padding-right: 4px;
}
.ag-blue .ag-group-value {
  padding-right: 2px;
}
.ag-blue .ag-group-checkbox {
  padding-right: 2px;
}
.ag-blue .ag-footer-cell {
  font-style: italic;
}
.ag-blue .ag-menu {
  border: 1px solid #808080;
  background-color: var(--grey01);
  cursor: default;
  font-family: Calibri, "Segoe UI", Thonburi, Arial, Verdana, sans-serif;
  font-size: 10pt;
}
.ag-blue .ag-menu .ag-tab-header {
  background-color: var(--primary);
}
.ag-blue .ag-menu .ag-tab {
  padding: 6px 8px 6px 8px;
  margin: 2px 2px 0px 2px;
  display: inline-block;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.ag-blue .ag-menu .ag-tab-selected {
  background-color: var(--primary-lighter);
  border-right: 1px solid var(--grey03);
  border-left: 1px solid var(--grey03);
  border-top: 1px solid var(--grey03);
}
.ag-blue .ag-menu-separator {
  border-top: 1px solid var(--grey03);
}
.ag-blue .ag-menu-option-active {
  background-color: var(--grey04);
}
.ag-blue .ag-menu-option-icon {
  padding: 2px 4px 2px 4px;
  vertical-align: middle;
}
.ag-blue .ag-menu-option-text {
  padding: 2px 4px 2px 4px;
  vertical-align: middle;
}
.ag-blue .ag-menu-option-shortcut {
  padding: 2px 2px 2px 20px;
  vertical-align: middle;
}
.ag-blue .ag-menu-option-popup-pointer {
  padding: 2px 4px 2px 4px;
  vertical-align: middle;
}
.ag-blue .ag-menu-option-disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.ag-blue .ag-menu-column-select-wrapper {
  margin: 2px;
}
.ag-blue .ag-filter-checkbox {
  position: relative;
  top: 2px;
  left: 2px;
}
.ag-blue .ag-filter-header-container {
  border-bottom: 1px solid var(--grey03);
}
.ag-blue .ag-filter-apply-panel {
  border-top: 1px solid var(--grey03);
  padding: 2px;
}
.ag-blue .ag-filter-value {
  margin-left: 4px;
}
.ag-blue .ag-selection-checkbox {
  padding-right: 4px;
}
.ag-blue .ag-paging-panel {
  padding: 4px;
}
.ag-blue .ag-paging-button {
  margin-left: 4px;
  margin-right: 4px;
}
.ag-blue .ag-paging-row-summary-panel {
  display: inline-block;
  width: 300px;
}
.ag-blue .ag-tool-panel {
  background-color: var(--grey01);
  border-right: 1px solid var(--grid-cell-border);
  border-bottom: 1px solid var(--grid-cell-border);
  border-top: 1px solid var(--grid-cell-border);
  color: var(--grey10);;
}
.ag-blue .ag-status-bar {
  color: var(--grey10);;
  background-color: var(--grey01);
  font-size: 10pt;
  height: 22px;
  border-bottom: 1px solid var(--grid-cell-border);
  border-left: 1px solid var(--grid-cell-border);
  border-right: 1px solid var(--grid-cell-border);
  padding: 2px;
}
.ag-blue .ag-status-bar-aggregations {
  float: right;
}
.ag-blue .ag-status-bar-item {
  padding-left: 10px;
}
.ag-blue .ag-column-drop-cell {
  background: #ddebf7;
  color: var(--grey10);
  border: 1px solid var(--grey07);
}
.ag-blue .ag-column-drop-cell-ghost {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.ag-blue .ag-column-drop-cell-text {
  padding-left: 2px;
  padding-right: 2px;
}
.ag-blue .ag-column-drop-cell-button {
  border: 1px solid transparent;
  padding-left: 2px;
  padding-right: 2px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.ag-blue .ag-column-drop-cell-button:hover {
  border: 1px solid var(--grid-cell-border);
}
.ag-blue .ag-column-drop-empty-message {
  padding-left: 2px;
  padding-right: 2px;
  color: var(--grey05);
}
.ag-blue .ag-column-drop-icon {
  padding-right: 4px;
}
.ag-blue .ag-column-drop {
  background-color:var(--grey01);
}
.ag-blue .ag-column-drop-horizontal {
  padding: 4px 4px 4px 4px;
  border-top: 1px solid var(--grid-cell-border);
  border-left: 1px solid var(--grid-cell-border);
  border-right: 1px solid var(--grid-cell-border);
}
.ag-blue .ag-column-drop-horizontal .ag-column-drop-cell {
  padding: 2px;
}
.ag-blue .ag-column-drop-vertical {
  padding: 4px 4px 10px 4px;
  border-bottom: 1px solid var(--grid-cell-border);
}
.ag-blue .ag-column-drop-vertical .ag-column-drop-cell {
  margin-top: 2px;
}
.ag-blue .ag-column-drop-vertical .ag-column-drop-empty-message {
  text-align: center;
  padding: 5px;
}
.ag-blue .ag-pivot-mode {
  border-bottom: 1px solid var(--grid-cell-border);
  padding: 4px;
  background-color: var(--grey01);
}
.ag-blue .ag-tool-panel .ag-column-select-panel {
  border-bottom: 1px solid var(--grid-cell-border);
}
.ag-blue .ag-select-agg-func-popup {
  cursor: default;
  position: absolute;
  font-size: 12px;
  border: 1px solid var(--grid-cell-border);
}
.ag-blue .ag-select-agg-func-item {
  padding-left: 2px;
  padding-right: 2px;
}
.ag-blue .ag-select-agg-func-item:hover {
  background-color: var(--grey04);
}
