@import "./styles/theme.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import "./styles/bootstrap_pulsar/bootstrap.spacelab.pulsar.css";
@import "~react-datepicker/dist/react-datepicker.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import './styles/aggrid_pulsar/theme-blue-pulsar.css';
@import '~ag-grid-community/dist/styles/agGridClassicFont.css';
@import './styles/react_select_pulsar/Select_pulsar.css';

Form {
  background-color : var(--grey00);
}

.react-datepicker {
  font-size: 1em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.dx-header-row {
  background:#47719E;
  color: white !important;
}

.dx-master-detail-cell .dx-header-row {
  background:lightgray !important;
  color: black !important;
}

.dx-master-detail-cell {
  padding: 5px !important;
}
