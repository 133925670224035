:root {
    --background: #F4F5F2;
    
    --white: #ffffff;

    --text: black;
    --text-inverted: white;

    --primary: #4C92ED;
    --primary-lightest: #9EC4F5;
    --primary-lighter: #66afe9;
    --primary-darker: #5D9CEF;  
    --primary-darkest: #1A73E8;
    
    
    /* --primary: #94D404;
    --primary-lightest: #E5FDAC;
    --primary-lightest: #D1FC6E;
    --primary-lighter: #C4FB45;
    --primary-lighter: #B1FA07;
    --primary-light: #A3E705;
    --primary-darker: #7FB404;
    --primary-darkest: #6A9603;
    --primary-darkest: #4A6902; */


    --success : #468847;
    --success-lighter : #c1e2b3;
    --success-darker : #356635;

    --info : #5bc0de;
    --info-lighter : #c4e3f3;
    --info-darker : #3db5d8;


    --default : #9CA2AB;
    --default-lighter : #D3D6D9;
    --default-darker : #B7BCC2;
    --default-darkest : #50565E;
/*     
    --default : #125BBA;
    --default-lighter : #1A73E8;
    --default-darker : #0F4A98;
    --default-darkest : #082955; */

    --grey00: #FFFFFF;
    --grey01: #F7F7F8;
    --grey02: #EEEFF1;
    --grey03: #D3D6D9;
    --grey04: #B7BCC2;
    --grey05: #9CA2AB;
    --grey06: #808893;
    --grey07: #676F79;
    --grey08: #50565E;
    --grey09: #383C42;
    --grey10: #1C1E21;

    
    --grid-header : var( --grey05);
    --grid-row-even : var(--grey00);
    --grid-row-odd :var(--grey01);
    --grid-row-selected : var(--grey03);
    --grid-border : var(--grey04);
    --grid-header-border : var(--grey00);
    --grid-cell-border : var(--grey02);
  }
